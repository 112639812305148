import React from 'react'
import * as FlowStyles from '../../../styles/pages/price/Flow.css'

const Flow: React.VFC = () => (
  <section className={FlowStyles.Container}>
    <h1 className={FlowStyles.Headline}>導入までの流れ</h1>
    <p className={FlowStyles.Lead}>最短3営業日で利用可能</p>
    <ol className={FlowStyles.Catalog}>
      <li className={FlowStyles.Catalog_Item}>
        <p className={FlowStyles.Catalog_Number}>01</p>
        <h2 className={FlowStyles.Catalog_Headline}>お問い合わせ</h2>
        <img src="/assets/images/price/flow1.webp" width="187" height="66" alt="" className={FlowStyles.Catalog_Image} />
        <p className={FlowStyles.Catalog_Lead}>
          サイト運営・改善のお悩みなど、
          <a href="https://karte.io/enterprise/" target="blank" className={FlowStyles.Catalog_LinkText}>
            こちら
          </a>
          からお気軽にご相談ください。
        </p>
      </li>
      <li className={FlowStyles.Catalog_Item}>
        <p className={FlowStyles.Catalog_Number}>02</p>
        <h2 className={FlowStyles.Catalog_Headline}>お打ち合わせ</h2>
        <img src="/assets/images/price/flow2.webp" width="187" height="66" alt="" className={FlowStyles.Catalog_Image} />
        <p className={FlowStyles.Catalog_Lead}>活用シーンや事例のご紹介、お客様のサイトでデモも可能です。</p>
      </li>
      <li className={FlowStyles.Catalog_Item}>
        <img
          src="/assets/images/price/comingsoon.webp"
          width="190"
          height="69"
          alt="ご契約開始（月初め）"
          className={FlowStyles.Catalog_Bubble}
        />
        <p className={FlowStyles.Catalog_Number}>03</p>
        <div className={FlowStyles.Catalog_Inner}>
          <h2 className={FlowStyles.Catalog_Headline}>
            概要レクチャー / <span className={FlowStyles.Catalog_Line}>設定サポート</span>
          </h2>
          <p className={FlowStyles.Catalog_Note}>個別・オンライン</p>
        </div>
        <img src="/assets/images/price/flow3.webp" width="187" height="66" alt="" className={FlowStyles.Catalog_Image} />
        <p className={FlowStyles.Catalog_Lead}>お客様のサイトのKPI、体制、活用の目的を踏まえ、スムーズなご利用開始をサポート。</p>
      </li>
      <li className={FlowStyles.Catalog_Item}>
        <p className={FlowStyles.Catalog_Number}>04</p>
        <h2 className={FlowStyles.Catalog_Headline}>運用開始〜サポート</h2>
        <img src="/assets/images/price/flow4.webp" width="187" height="66" alt="" className={FlowStyles.Catalog_Image} />
        <p className={FlowStyles.Catalog_Lead}>専門のサポート体制で、日々のお困りごとをチャットでスピーディに解決します。</p>
      </li>
    </ol>
  </section>
)

export default Flow

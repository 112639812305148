import React from 'react'
import * as PlanStyles from '../../../styles/pages/price/Plan.css'
import PlanMobile from './PlanMobile'
import PlanDesktop from './PlanDesktop'

export const PLANS = [
  {
    title: '主な制限値（一部）',
    provided: false,
    plans: [
      {
        title: '計測PV数',
        description: 'タグを設置し、KARTE Blocksで管理またはデータ計測するページにおける月間ページビュー数です。',
        content: '〜100万PV/月',
        option: '上限引き上げ可',
        link: undefined,
      },
      {
        title: 'カスタムイベント計測',
        description: '基本タグでは取得できない情報を取得します。セグメントやゴールの設定で利用可能です。',
        content: '計測PV数に準ずる',
        option: '上限引き上げ可',
        link: undefined,
      },
      {
        title: '管理画面アカウント数',
        description: undefined,
        content: '20個',
        option: '上限引き上げ可',
        link: undefined,
      },
      {
        title: '登録ページ数',
        description: 'KARTE Blocksでブロックの登録・配信を行うページです。コンバージョン計測だけのページ等は含まれません。',
        content: '20個',
        option: '上限引き上げ可',
      },
      {
        title: 'ゴール数',
        description: 'KARTE Blocksで計測・解析するコンバージョンです。',
        content: '20個',
        option: '−',
        link: undefined,
      },
    ],
  },
  {
    title: 'ブロックの編集・書き換え',
    provided: true,
    plans: [
      {
        title: 'ビジュアルノーコードエディター（β）',
        description: undefined,
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'コードエディター',
        description: 'HTML/CSS/Scriptで柔軟にブロック編集することもできます。',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'プレビュー',
        description: undefined,
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'ブロックライブラリ',
        description: 'プロジェクトごとに作成したブロックをテンプレートとして保存・再利用できます。',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'ブロックストア（β）',
        description: 'プロのデザインエンジニアが作成した汎用的なブロックをすぐに利用できます。',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'ポップアップ / メールなどの他アクション',
        description: 'プロジェクトごとに作成したブロックをテンプレートとして保存・再利用できます。',
        content: false,
        option: '○',
        link: 'https://karte.io/product/action/',
      },
    ],
  },
  {
    title: 'サイトへの配信制御',
    provided: true,
    plans: [
      {
        title: 'スケジュール設定',
        description: undefined,
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'ABテスト',
        description: undefined,
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'パーソナライズ',
        description:
          '来訪回数や特定ページの閲覧回数などのサイト閲覧データや、会員登録情報などのユーザーデータを組み合わせてターゲティングできます。',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'ブロック効果',
        description: 'KARTE Blocksで管理・運用しているブロックごとに表示数やクリック率などのデータを自動で集計します。',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'セグメント別効果',
        description: 'KARTE Blocksによるサイト改修・ABテストの結果から、ユーザーセグメントごとに効果を再集計・可視化します。',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'セッションログ再生',
        description: 'セッション時のユーザーによる閲覧の様子を再現します',
        content: true,
        option: '−',
        link: undefined,
      },
      {
        title: 'より詳細なユーザー分析',
        description:
          'KARTEを活用して、来訪ユーザーをより多様な視点で分析・可視化することが可能です。ユーザーのファネル分析やダッシュボード、ユーザーリストの作成など',
        content: false,
        option: '○',
        link: 'https://karte.io/product/insight/',
      },
    ],
  },
]

const Plan: React.VFC = () => (
  <section className={PlanStyles.Container}>
    <div className={PlanStyles.Heading_Container}>
      <div>
        <h2 className={PlanStyles.Heading}>ベースプラン詳細</h2>
        <p className={PlanStyles.Lead}>やりたいことに応じてオプションの追加も可能</p>
      </div>
      <a href="https://karte.io/enterprise/" target="_blank" className={PlanStyles.Button}>
        導入検討のお問い合せ
      </a>
    </div>
    <PlanMobile />
    <PlanDesktop />
  </section>
)

export default Plan

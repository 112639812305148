import React from 'react'
import { PageContextPropsType } from '../../../i18n/i18n'
import SEO from '../../components/SEO'
import { texts } from '../../utils/texts'
import Top from '../../components/pages/price/Top'
import Support from '../../components/pages/price/Support'
import LayoutPrice from '../../components/LayoutPrice'
import Plan from '../../components/pages/price/Plan'
import Flow from '../../components/pages/price/Flow'

export default function PricePage({ pageContext }: PageContextPropsType) {
  return (
    <LayoutPrice pageContext={pageContext}>
      <SEO title={texts.price.title} description={texts.price.description} path="/price/" />
      <main>
        <Top />
        <Plan />
        <Flow />
        <Support />
      </main>
    </LayoutPrice>
  )
}

import React from 'react'
import * as HomeSupportStyles from '../../../styles/HomeSupport.css'
import cx from 'classnames/bind'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const seminars = [
  {
    headline: 'LPOの効果的な進め方',
    description: '所要時間 48min',
    link: 'https://karte.io/seminar/on-demand/20221024_karte-blocks_ondemand/',
    imageSrc: '/assets/images/home/seminar_1.webp',
  },
  {
    headline: 'KARTE Blocksにおけるオンボーディング改善のリアル',
    description: '所要時間 40min',
    link: 'https://karte.io/seminar/on-demand/20220526-saas-blocks-ondemand/',
    imageSrc: '/assets/images/home/seminar_2.webp',
  },
]

const materials = [
  {
    headline: '直感的なサイト編集・CVR向上｜KARTE Blocks',
    description: '#サイト管理・改善',
    link: 'https://blocks.karte.io/ebook/',
    imageSrc: '/assets/images/home/material_1.webp',
  },
  {
    headline: '成功事例に学ぶ、LPOのキホン',
    description: '#サイト管理・改善',
    link: 'https://blocks.karte.io/ebook/lpo/',
    imageSrc: '/assets/images/home/material_2.webp',
  },
]

const SupportList: React.VFC = () => (
  <div className={HomeSupportStyles.Info}>
    <div className={HomeSupportStyles.Info_Block}>
      <div className={HomeSupportStyles.Info_Headlines}>
        <h3 className={HomeSupportStyles.Info_Headline}>セミナー・イベント</h3>
        <a
          href="https://karte.io/seminar/"
          target={'_blank'}
          className={cx(HomeSupportStyles.Info_Button, SiteWideStyles.DisplayDesktopOnly)}
        >
          一覧を見る
          <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
        </a>
      </div>
      <ul className={HomeSupportStyles.Info_Listing}>
        {seminars.map((seminar) => (
          <li key={seminar.headline}>
            <a href={seminar.link} target="_blank" className={HomeSupportStyles.Info_Card}>
              <img src={seminar.imageSrc} alt={seminar.headline} className={HomeSupportStyles.Info_CardImage} height={116} width={216} />
              <div className={HomeSupportStyles.Info_CardContent}>
                <p className={HomeSupportStyles.Info_CardHeadline}>{seminar.headline}</p>
                <p className={HomeSupportStyles.Info_CardDescription}>{seminar.description}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <a
        href="https://karte.io/seminar/"
        target={'_blank'}
        className={cx(HomeSupportStyles.Info_Button, HomeSupportStyles.Info_MobileButton, SiteWideStyles.DisplayMobileOnly)}
      >
        一覧を見る
        <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
      </a>
    </div>
    <div className={HomeSupportStyles.Info_Block}>
      <div className={HomeSupportStyles.Info_Headlines}>
        <h3 className={HomeSupportStyles.Info_Headline}>お役立ち資料</h3>
        <a
          href="https://karte.io/resource/ebook/"
          target={'_blank'}
          className={cx(HomeSupportStyles.Info_Button, SiteWideStyles.DisplayDesktopOnly)}
        >
          一覧を見る
          <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
        </a>
      </div>
      <ul className={HomeSupportStyles.Info_Listing}>
        {materials.map((material) => (
          <li key={material.headline}>
            <a href={material.link} target="_blank" className={HomeSupportStyles.Info_Card}>
              <img src={material.imageSrc} alt={material.headline} className={HomeSupportStyles.Info_CardImage} height={116} width={216} />
              <div className={HomeSupportStyles.Info_CardContent}>
                <p className={HomeSupportStyles.Info_CardHeadline}>{material.headline}</p>
                <p className={HomeSupportStyles.Info_CardDescription}>{material.description}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <a
        href="https://karte.io/resource/ebook/"
        target={'_blank'}
        className={cx(HomeSupportStyles.Info_Button, HomeSupportStyles.Info_MobileButton, SiteWideStyles.DisplayMobileOnly)}
      >
        一覧を見る
        <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
      </a>
    </div>
  </div>
)

export default SupportList

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as TopStyles from '../../../styles/pages/price/Top.css'
import classNames from 'classnames'

const Top: React.VFC = () => {
  const topImage = useStaticQuery(graphql`
    query {
      squishy: file(relativePath: { eq: "site-wide/squishy_texture.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <section className={TopStyles.Container}>
      <div className={TopStyles.Frame}>
        <GatsbyImage image={topImage.squishy.childImageSharp.gatsbyImageData} alt="" className={TopStyles.Image} />
      </div>
      <div className={TopStyles.Inner}>
        <div className={TopStyles.Head}>
          <h1 className={TopStyles.Headline}>
            KARTE Blocks <span className={TopStyles.Line}>料金プラン</span>
          </h1>
          <p className={TopStyles.Lead}>
            サイト規模、業界業種問わず<span className={TopStyles.Line}>ご利用いただいています</span>
          </p>
        </div>
        <dl className={TopStyles.Catalog}>
          <div className={TopStyles.Catalog_Item}>
            <dt className={TopStyles.Catalog_Headline}>
              <span className={TopStyles.Catalog_Small}>初期費用</span>10万円
            </dt>
            <dd className={TopStyles.Catalog_Summary}>
              導入プログラム・
              <br />
              学習コンテンツのご提供
            </dd>
          </div>
          <div className={classNames(TopStyles.Catalog_Item, 'monthly')}>
            <dt className={TopStyles.Catalog_Headline}>
              <span className={TopStyles.Catalog_Small}>月額固定</span>12.5万円〜
              <span className={TopStyles.Catalog_SubHeadline}>※主に計測PV数により決定</span>
            </dt>
            <dd className={TopStyles.Catalog_Summary}>
              従量課金式による
              <br />
              自動料金変動なし
            </dd>
          </div>
          <div className={TopStyles.Catalog_Item}>
            <dt className={TopStyles.Catalog_Headline}>Fair Policy</dt>
            <dd className={TopStyles.Catalog_Summary}>
              実際に運用するページのPV数を主に参照。
              <br />
              使っていないページ分の費用は<span className={TopStyles.Line}>抑えられます。</span>
            </dd>
          </div>
        </dl>
      </div>
      <a href="https://karte.io/enterprise/" target="_blank" className={TopStyles.Button}>
        まずは、お問い合わせ
      </a>
    </section>
  )
}

export default Top

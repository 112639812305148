import React from 'react'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as PlanStyles from '../../../styles/pages/price/Plan.css'
import { PLANS } from './Plan'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import { Fragment } from 'react'

const PlanDesktop = () => (
  <article className={classNames(PlanStyles.Info, SiteWideStyles.DisplayDesktopOnly)}>
    <div className={PlanStyles.Table}>
      <div>
        {PLANS.map((item, index) => (
          <dl className={PlanStyles.Table_Container} key={`plans1_${index}`}>
            <dt className={classNames(PlanStyles.Info_Heading, PlanStyles.Info_Heading_Outer)}>
              {item.provided && <span className={PlanStyles.Tag}>提供機能</span>}
              {item.title}
            </dt>
            {item.plans.map((plan, index) => (
              <dd className={PlanStyles.Item_Heading} key={`feature_${index}`}>
                <span className={plan.description && PlanStyles.Hint}>
                  {plan.title}
                  {plan.description && (
                    <>
                      <FontAwesomeIcon icon={faCircleQuestion} width="16px" height="26px" color={'rgba(0, 16, 14, 0.32)'} />
                      <div className={PlanStyles.Hint_Content}>{plan.description}</div>
                    </>
                  )}
                </span>
              </dd>
            ))}
          </dl>
        ))}
      </div>
      <div className={classNames(PlanStyles.Inner, 'shadow')}>
        {PLANS.map((item, index) => (
          <dl className={PlanStyles.Table_Container} key={`plans2_${index}`}>
            <dt className={PlanStyles.Table_Heading}>{index === 0 && 'ベースプラン内容'}</dt>
            {item.plans.map((plan, index) => (
              <Fragment key={`content_${index}`}>
                {typeof plan.content !== 'boolean' ? (
                  <dd className={classNames(PlanStyles.Content, 'base-plan')}>{plan.content}</dd>
                ) : (
                  <dd className={PlanStyles.Option_light}>{plan.content ? '○' : '×'}</dd>
                )}
              </Fragment>
            ))}
          </dl>
        ))}
      </div>
      <div className={PlanStyles.Inner}>
        {PLANS.map((item, index) => (
          <dl className={PlanStyles.Table_Container} key={`plans3_${index}`}>
            <dt className={PlanStyles.Table_Heading_Light}>{index === 0 && '（追加オプション）'}</dt>
            {item.plans.map((plan, index) => (
              <dd className={PlanStyles.Content} key={`option_${index}`}>
                {plan.option}
                {plan.link && (
                  <>
                    <p className={classNames(PlanStyles.KARTE_Link_Outer, 'desktop-only')}>
                      （
                      <a href={plan.link} target="_blank" className={PlanStyles.KARTE_Link}>
                        KARTE
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} width="13px" height="20px" />
                      </a>
                      の併用）
                    </p>
                    <p className={classNames(PlanStyles.KARTE_Link_Outer, 'tablet-only')}>
                      <a href={plan.link} target="_blank" className={PlanStyles.KARTE_Link}>
                        KARTE
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} width="13px" height="20px" />
                      </a>
                      の併用で可能
                    </p>
                  </>
                )}
              </dd>
            ))}
          </dl>
        ))}
      </div>
    </div>
  </article>
)

export default PlanDesktop

import React from 'react'
import SupportList from './SupportList'
import * as SupportStyles from '../../../styles/pages/price/Support.css'

const Support: React.VFC = () => (
  <div className={SupportStyles.Container}>
    <SupportList />
  </div>
)

export default Support

import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as PlanStyles from '../../../styles/pages/price/Plan.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import { PLANS } from './Plan'
import { Fragment } from 'react'

const PlanMobile = () => (
  <article className={classNames(PlanStyles.Info, SiteWideStyles.DisplayMobileOnly)}>
    {PLANS.map((item, index) => (
      <Fragment key={`plan_sp_${index}`}>
        <div className={PlanStyles.Info_Heading_Outer}>
          {item.provided && (
            <div>
              <span className={PlanStyles.Tag}>提供機能</span>
            </div>
          )}
          <h3 className={PlanStyles.Info_Heading}>{item.title}</h3>
        </div>
        <dl className={PlanStyles.Catalog}>
          {item.plans.map((plan, index) => (
            <div className={PlanStyles.Item} key={`plan_sp_${index}`}>
              <dt>
                <h4 className={PlanStyles.Item_Heading}>{plan.title}</h4>
                {plan.description && <p className={PlanStyles.Description}>{plan.description}</p>}
              </dt>
              <dd>
                {typeof plan.content !== 'boolean' ? (
                  <>
                    <p className={PlanStyles.Content}>{plan.content}</p>
                    <p className={PlanStyles.Option}>オプション：{plan.option}</p>
                  </>
                ) : (
                  // plan.content が true => '○', false => '×' とKARTEへのリンクを表示
                  <>
                    <p className={PlanStyles.Option_light}>{plan.content ? '○' : '×'}</p>
                    {plan.link && (
                      <p className={classNames(PlanStyles.Option_light, PlanStyles.KARTE_Link_Outer)}>
                        （
                        <a href={plan.link} target="_blank" className={PlanStyles.KARTE_Link}>
                          KARTE
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} width="13px" height="20px" />
                        </a>
                        の併用なら可能）
                      </p>
                    )}
                  </>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </Fragment>
    ))}
  </article>
)

export default PlanMobile
